import spotify from "./Spotify.png";
import netflix from "./Netflix.png";
import twitter from "./Twitter.png";
import instagram from "./Instagram.png";
import photos from "./Photos.png";
import pinterest from "./Pinterest.png";
import reddit from "./Reddit.png";
import snapchat from "./Snapchat.png";
import tiktok from "./TikTok.png";

const appIcons = [
  { src: spotify, alt: "Spotify" },
  { src: netflix, alt: "Netflix" },
  { src: twitter, alt: "Twitter" },
  { src: instagram, alt: "Instagram" },
  { src: photos, alt: "Photos" },
  { src: pinterest, alt: "Pinterest" },
  { src: reddit, alt: "Reddit" },
  { src: snapchat, alt: "Snapchat" },
  { src: tiktok, alt: "TikTok" },
];

export default appIcons;
