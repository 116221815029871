// AppIcon.js
import React, { useRef, useState, useEffect } from "react";
import "./AppIcons.css";
import appIcons from "../assets/appIcons";

const AppIcons = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
      if (!isScrolling) {
        setIsScrolling(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isScrolling]);

  const pageHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;

  // Determine the scroll position as a fraction of the total scrollable area
  const scrollFraction = Math.min(scrollY / (pageHeight - windowHeight), 1);
  const leftOffset = window.innerWidth * 0.017;

  const iconPositions = isMobile
    ? [
        {
          // spotify
          src: appIcons[0].src,
          alt: appIcons[0].alt,
          rotation: -10,
          top: "37%",
          left: "22%",
        },
        {
          // netflix
          src: appIcons[1].src,
          alt: appIcons[1].alt,
          rotation: 9,
          top: "31%",
          left: "32%",
        },
        {
          // twitter
          src: appIcons[2].src,
          alt: appIcons[2].alt,
          rotation: -8,
          top: "31%",
          left: "62%",
        },
        {
          // instagram
          src: appIcons[3].src,
          alt: appIcons[3].alt,
          rotation: 10,
          top: "37%",
          left: "74%",
        },
        {
          // photos
          src: appIcons[4].src,
          alt: appIcons[4].alt,
          rotation: -12,
          top: "43%",
          left: "73%",
        },
        {
          // pinterest
          src: appIcons[5].src,
          alt: appIcons[5].alt,
          rotation: 12,
          top: "39%",
          left: "58%",
        },
        {
          // reddit
          src: appIcons[6].src,
          alt: appIcons[6].alt,
          rotation: 0,
          top: "34%",
          left: "48%",
        },
        {
          // snapchat
          src: appIcons[7].src,
          alt: appIcons[7].alt,
          rotation: -8,
          top: "39%",
          left: "37%",
        },
        {
          // tiktok
          src: appIcons[8].src,
          alt: appIcons[8].alt,
          rotation: 10,
          top: "43%",
          left: "18%",
        },
      ]
    : [
        {
          // spotify
          src: appIcons[0].src,
          alt: appIcons[0].alt,
          rotation: -10,
          top: "30%",
          left: "33%",
        },
        {
          // netflix
          src: appIcons[1].src,
          alt: appIcons[1].alt,
          rotation: 9,
          top: "24%",
          left: "43%",
        },
        {
          // twitter
          src: appIcons[2].src,
          alt: appIcons[2].alt,
          rotation: -8,
          top: "23%",
          left: "54%",
        },
        {
          // instagram
          src: appIcons[3].src,
          alt: appIcons[3].alt,
          rotation: 10,
          top: "29%",
          left: "62%",
        },
        {
          // photos
          src: appIcons[4].src,
          alt: appIcons[4].alt,
          rotation: -12,
          top: "43%",
          left: "62%",
        },
        {
          // pinterest
          src: appIcons[5].src,
          alt: appIcons[5].alt,
          rotation: 12,
          top: "35%",
          left: "55%",
        },
        {
          // reddit
          src: appIcons[6].src,
          alt: appIcons[6].alt,
          rotation: 0,
          top: "32%",
          left: "48%",
        },
        {
          // snapchat
          src: appIcons[7].src,
          alt: appIcons[7].alt,
          rotation: -8,
          top: "35%",
          left: "40%",
        },
        {
          // tiktok
          src: appIcons[8].src,
          alt: appIcons[8].alt,
          rotation: 10,
          top: "45%",
          left: "35%",
        },
      ];

  return (
    <div className="app-icons-container" ref={containerRef}>
      {iconPositions.map((icon) => {
        const initialLeft = parseFloat(icon.left);
        const initialTop = parseFloat(icon.top);

        const centerX = window.innerWidth / 2;
        const iconX = (initialLeft / 100) * window.innerWidth;
        const moveX = isMobile
          ? 0
          : ((centerX - iconX - leftOffset) * scrollFraction) / 10;
        const moveY = isMobile
          ? 0
          : ((window.innerHeight - (initialTop / 100) * window.innerHeight) *
              scrollFraction) /
            6;

        return (
          <img
            key={icon.src}
            src={icon.src}
            alt={icon.alt}
            style={{
              position: "absolute",
              top: `${
                isScrolling ? Math.min(initialTop + moveY, 100) : initialTop
              }%`,
              left: `${
                isScrolling ? Math.min(initialLeft + moveX, 100) : initialLeft
              }%`,
              transform: `
                rotate(${icon.rotation + scrollY / 10}deg) 
                scale(${Math.max(1 - scrollY / 500, 0.3)})
              `,
              opacity: Math.max(1 - scrollY / 300, 0),
            }}
            className="app-icon"
          />
        );
      })}
    </div>
  );
};

export default AppIcons;
