import React from "react";
import "./App.css";
import Header from "./components/Header";
import AppIcons from "./components/AppIcons";
import MainContent from "./components/MainContent";
import EmailForm from "./components/EmailForm";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <AppIcons />
      <Header />
      <MainContent />
      <EmailForm />
      <Footer />
    </div>
  );
}

export default App;
