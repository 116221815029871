import React, { useState } from "react";
import "./EmailForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/FooterLogo.png";

const EmailForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); // For success or error messages
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send email to your Node.js backend
      const response = await fetch(`https://qaz942tc29.execute-api.us-west-1.amazonaws.com/email?email=${encodeURIComponent(email)}`, {
        method: 'POST'
      });

      const result = await response.json();
      if (result.statusCode == 200) {
        setMessage(result.message);
      } else {
        setMessage('Failed to submit email.');
      }
    } catch (error) {
      console.error('Error submitting email:', error);
      setMessage('An error occurred. Please try again.');
    }

    setEmail(""); // Reset the form
  };


  return (
    <div className="email-form">
      <img src={logo} alt="Logo" className="email-form-logo" />
      <h1 className="email-form-title">join our waitlist</h1>
      <form onSubmit={handleSubmit} className="email-form-container">
        <div className="input-container">
          <FontAwesomeIcon icon={faEnvelope} className="email-icon" />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="enter your email"
            className="email-input"
            required
          />
        </div>
        <button type="submit" className="submit-button">
          <FontAwesomeIcon icon={faArrowRight} className="arrow-icon" />
        </button>
      </form>
      {message && <p className="form-message">{message}</p>} {/* Display success/error message */}
    </div>
  );
};

export default EmailForm;
