import React, { useState } from "react";
import "./Header.css";
import logo from "../assets/FooterLogo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <header className="header">
      {isMenuOpen && <div className="background-blur"></div>}
      <div className={`header-content ${isMenuOpen ? "hide-hamburger" : ""}`}>
        <img src={logo} alt="Nostalgia Logo" className="logo" />
        <div className="hamburger-icon" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <nav className={`nav-links ${isMenuOpen ? "show" : ""}`}>
          <a href="#about">about</a>
          <a href="#careers">careers</a>
        </nav>
      </div>
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}
    </header>
  );
};

export default Header;
