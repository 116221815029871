import React from "react";
import "./MainContent.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

const MainContent = () => {
  return (
    <div className="main-content">
      <h1>nostalgia</h1>
      <p>
        relive <strong>memories</strong> from your favorite apps
      </p>
      <button
        onClick={() =>
          window.open(
            "https://www.youtube.com/watch?v=f2Ao1U-xPrg&t=1s",
            "_blank"
          )
        }
      >
        <FontAwesomeIcon icon={faPlay} className="play-icon" />
        watch 30 sec. demo
      </button>
    </div>
  );
};

export default MainContent;
