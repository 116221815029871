import React from "react";
import "./Footer.css";
import {
  FaTwitter,
  FaYoutube,
  FaLinkedin,
  FaInstagram,
  FaTiktok,
  FaDiscord,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <div className="footer-logo">nostalgia</div>
          <div className="footer-copyright">© 2024</div>
        </div>
        <div className="social-icons">
          <a
            href="https://twitter.com/nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaTwitter />
          </a>
          <a
            href="https://youtube.com/nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaYoutube />
          </a>
          <a
            href="https://linkedin.com/company/nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://instagram.com/nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaInstagram />
          </a>
          <a
            href="https://tiktok.com/@nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaTiktok />
          </a>
          <a
            href="https://discord.gg/nostalgia"
            target="_blank"
            rel="noopener noreferrer"
            className="social-icon"
          >
            <FaDiscord />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
